<template>
  <div id="notifications-add">
    <form class="form" @input="inputCapture">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{ $tc('app.notification.title', 1) }}</span>
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model="form.msg_title"
          :disabled="!canHaveActionPermission"
          :placeholder="placeholderTranslations.title"
          :title="placeholderTranslations.title"
          type="text"
          required
        />
      </div>
      <div
        class="input-group"
        v-if="checkHasPermission('view-roles') && !msg_id"
      >
        <multiselect-view
          :handleProps="rolesProps"
          :handleEvents="rolesEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group" v-if="!msg_id && isMemberSelected">
        <multiselect-view
          :handleProps="groupsProps"
          :handleEvents="groupsEvents"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
      <div class="input-group" v-if="!msg_id">
        <multiselect-view
          :handleProps="usersProps"
          :handleEvents="usersEvents"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
      <div class="input-group no-tag-close" v-if="msg_id && isUserMessage">
        <multiselect-view
          :handleProps="usersPresentProps"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
      <div class="input-group">
        <textarea
          class="alt max-w"
          rows="10"
          :disabled="!canHaveActionPermission"
          v-model="form.msg_description"
          :placeholder="placeholderTranslations.description"
          :title="placeholderTranslations.description"
          type="text"
          required
        />
      </div>
      <div class="text-mini" v-if="canHaveActionPermission">
        * {{ $t('app.requiredFields') }}
      </div>
      <button
        class="acc long"
        :disabled="isApiLoading"
        v-if="canHaveActionPermission"
        @click="manageNotification"
      >
        <span :class="{ progress: isApiLoading }">
          <i class="fa fa-save"></i>
          <span>{{ $t('app.save') }}</span>
        </span>
      </button>
    </form>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  name: 'NotificationAdd',
  mixins: [formMixin],
  props: {
    msg_id: {
      type: [Number, String],
    },
  },
  data () {
    return {
      notificationId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      rolesOptions: [],
      groups: [],
      usersData: [],
      transformMap: {
        // 'mmb_id': 'id' - not used
        msg_id: 'id',
      },
      includeKeys: [
        'id',
        'msg_id',
        'role_ids',
        'group_ids',
        'user_ids',
        'msg_title',
        'msg_description'
      ],
    };
  },
  computed: {
    canCreateMessage () {
      return this.checkHasPermission('create-messages');
    },
    canEditMessage () {
      // Changed this because the client doesn't want to edit messages anymore
      // return this.checkHasPermission('edit-messages');
      return this.checkHasPermission('edit-messages2');
    },
    canHaveActionPermission () {
      if (this.notificationId) {
        return this.canEditMessage;
      }
      return this.canCreateMessage;
    },
    isUserMessage () {
      return this.form.is_user_message;
    },
    isMemberSelected () {
      const ROLE_MEMBER = 100;
      return Boolean(this.form.role_ids?.includes(ROLE_MEMBER));
    },
    rolesProps () {
      return {
        // __isFixedDrop: true,
        options: this.rolesOptions,
        placeholder: this.placeholderTranslations.member,
        title: this.placeholderTranslations.member,
        value: this.rolesOptions.filter(item => this.form.role_ids?.includes(item.rls_id)),
        searchable: true,
        label: 'rls_name',
      };
    },
    rolesEvents () {
      return {
        select: async (evt) => {
          this.$set(this.form, 'role_ids', [evt.rls_id]);

          // For multiple select
          // if (!this.form.role_ids) {
          //   this.$set(this.form, 'role_ids', [])
          // }
          // if (Array.isArray(evt)) {
          //   this.form.role_ids.push(...evt.map(el => el.rls_id))
          // } else {
          //   this.form.role_ids.push(evt.rls_id)
          // }
          await this.canAndFetchUsers();
          this.checkAlreadyCheckedUsers();
        },
        remove: async (evt) => {
          this.$delete(this.form, 'role_ids');

          // For multiple select
          // if (Array.isArray(evt)) {
          //   this.$set(this.form, 'role_ids', this.form.role_ids.filter(id => !evt.map(el => el.rls_id).includes(id)))
          // } else {
          //   this.$set(this.form, 'role_ids', this.form.role_ids.filter(id => id !== evt.rls_id))
          // }
          await this.canAndFetchUsers();
          this.checkAlreadyCheckedUsers();
        },
        // select: (evt) => this.$set(this.form, 'rls_id', evt.rls_id),
        // remove: () => this.$set(this.form, 'rls_id', null),
      };
    },
    groupsProps () {
      return {
        // __isFixedDrop: true,
        options: this.multiselectGroupAll(this.groups),
        // options: this.groups,
        placeholder: this.placeholderTranslations.groups,
        title: this.placeholderTranslations.groups,
        value: this.groups.filter(item => this.form.group_ids?.includes(item.grp_id)),
        searchable: true,
        closeOnSelect: false,
        groupValues: 'sub',
        groupLabel: 'label',
        groupSelect: true,
        multiple: true,
        label: 'grp_name',
      };
    },
    groupsEvents () {
      return {
        select: async (evt) => {
          if (!this.form.group_ids) {
            this.$set(this.form, 'group_ids', []);
          }
          if (Array.isArray(evt)) {
            this.form.group_ids.push(...evt.map(el => el.grp_id));
          } else {
            this.form.group_ids.push(evt.grp_id);
          }
          await this.canAndFetchUsers();
          this.checkAlreadyCheckedUsers();
        },
        remove: async (evt) => {
          if (Array.isArray(evt)) {
            this.$set(this.form, 'group_ids', this.form.group_ids.filter(id => !evt.map(el => el.grp_id).includes(id)));
          } else {
            this.$set(this.form, 'group_ids', this.form.group_ids.filter(id => id !== evt.grp_id));
          }
          await this.canAndFetchUsers();
          this.checkAlreadyCheckedUsers();
        },
      };
    },
    usersProps () {
      return {
        // __isFixedDrop: true,
        options: this.multiselectGroupAll(this.usersData),
        // options: this.usersData,
        placeholder: this.placeholderTranslations.selectUser,
        title: this.placeholderTranslations.selectUser,
        value: this.usersData.filter(item => this.form.user_ids?.includes(item.usr_id)),
        searchable: true,
        closeOnSelect: false,
        groupValues: 'sub',
        groupLabel: 'label',
        groupSelect: true,
        multiple: true,
        label: 'usr_name',
      };
    },
    usersPresentProps () {
      return {
        // __isFixedDrop: true,
        options: this.formattedBaseUsers,
        placeholder: this.placeholderTranslations.users,
        title: this.placeholderTranslations.users,
        value: this.formattedBaseUsers,
        searchable: true,
        multiple: true,
        // disabled: true,
        label: 'usr_name',
      };
    },
    usersEvents () {
      return {
        select: (evt) => {
          if (!this.form.user_ids) {
            this.$set(this.form, 'user_ids', []);
          }
          if (Array.isArray(evt)) {
            this.form.user_ids.push(...evt.map(el => el.usr_id));
          } else {
            this.form.user_ids.push(evt.usr_id);
          }
        },
        remove: (evt) => {
          if (Array.isArray(evt)) {
            this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => !evt.map(el => el.usr_id).includes(id)));
          } else {
            this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => id !== evt.usr_id));
          }
        },
      };
    },
    formattedBaseUsers () {
      if (this.formBaseData.users) {
        return this.addAdditionalUserData(this.formBaseData.users);
      }
      return [];
    },
  },
  watch: {
    langChangeEventCo () {
      this.canAndFetchRoles();
    },
    isMemberSelected (val) {
      if (val) {
        this.$nextTick(() => {
          this.formManageInputPlaceholder(false);
          this.canAndFetchGroups();
        });
      }
    },
  },
  mounted () {
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetNotificationId();
    this.canAndFetchRoles();
    this.canAndFetchGroups();
    this.canAndFetchUsers();
  },
  methods: {
    ...mapActions({
      getRoles: 'getRoles',
      getGroups: 'getGroups',
      getUsers: 'getUsers',
      getNotification: 'getNotification',
      getOneNotification: 'getOneNotification',
      postNotification: 'postNotification',
      patchNotification: 'patchNotification',
    }),
    checkGetNotificationId () {
      this.notificationId = parseInt(this.$route.params.msg_id, 10) || null;
      if (this.notificationId) {
        this.fetchNotifications();
      }
    },
    checkAlreadyCheckedUsers () {
      // Roles & groups check
      const currentUsers = this.form.user_ids;
      if (Array.isArray(currentUsers) && currentUsers.length) {
        const allIds = this.usersData.map(user => user.usr_id);
        this.form.user_ids = currentUsers.reduce((acc, curr) => {
          if (allIds.includes(curr)) {
            acc.push(curr);
          }
          return acc;
        }, []);
      }
    },
    async canAndFetchRoles () {
      if (this.checkHasPermission('view-roles') && !this.msg_id) {
        await this.fetchRoles();
      }
    },
    async canAndFetchGroups () {
      if (!this.msg_id && this.isMemberSelected) {
        await this.fetchGroups();
      }
    },
    async canAndFetchUsers () {
      if (!this.msg_id) {
        await this.fetchUsersList();
      }
    },
    async fetchGroups () {
      const params = {
        grp_active: 1,
      };
      try {
        const res = await this.getGroups(params);
        this.groups = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchUsersList () {
      const params = {
        // grp_id: this.$route.params.group_id
        usr_active: 1,
        role_ids: this.form.role_ids?.join(','),
        group_ids: this.form.group_ids?.join(','),
      };
      try {
        const res = await this.getUsers(params);
        const tempData = res.data.data.map(responseObj => this.transformApiDataObject(responseObj, {}, { isKeepClone: false }));
        this.usersData = this.addAdditionalUserData(tempData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalUserData (tempData) {
      function fullUserName (data) {
        const userId = data.usr_id || '';
        const firstName = data.usr_first_name || '';
        const lastName = data.usr_last_name || '';
        return `${userId} - ${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.usr_name = fullUserName(data);
      }

      return tempData;
    },
    async fetchNotifications () {
      try {
        const res = await this.getOneNotification({ id: this.notificationId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('notification params:', this.form);
    },
    async fetchRoles () {
      try {
        const res = await this.getRoles({});
        this.rolesOptions = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async manageNotification () {
      this.isApiLoading = true;
      if (this.notificationId) {
        await this.editNotification();
      } else {
        await this.saveNotification();
      }
      this.isApiLoading = false;
    },
    async editNotification () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchNotification(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('notification params:', this.notification);
    },
    async saveNotification () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postNotification(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('notification params:', this.notification);
    },
    inputCapture (evt) {
      console.log('input', evt);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
    multiselectGroupAll (dataArr) {
      return [{
        label: this.$i18n.t('app.selectAll'),
        sub: dataArr,
      }];
    },
  },
};
</script>

<style lang="scss" scoped>
#notifications-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }

  ::v-deep {
    .no-tag-close {
      .multiselect__tag-icon {
        display: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .form {
      input,
      .multiselect-view,
      textarea {
        width: 500px;
      }
    }
  }
}
</style>
