<template>
  <div id="notifications-table-list">
    <div class="top-head">
      <div class="left">
        <router-link
          :to="getDynamicPreviousRoute"
          class="btn"
          v-if="checkHasPermission('') && canGoBack"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>
        <span>{{$tc('app.notification.title',2)}}</span>
        <!-- <span :title="$i18n.tc('app.client.title',1)" v-if="isMultipleClients">({{clientData.name}})</span> -->
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @notification-select="notificationSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{name: 'NotificationAdd'}"
          v-if="checkHasPermission('create-messages')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{$t('app.notification.addNew')}}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div
        class="table-wrap"
        v-if="tableShowFunc"
      >
        <table-wrapper
          class="table"
          :rows="notificationsData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @click-remove="removeNotification"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'NotificationsTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  data () {
    return {
      notificationsData: [],
      tableShowFunc: false,
      notificationSelection: null,
      intervalCode: null,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        msg_id: 'id',
        msg_title: 'name',
        msg_created: 'created',
        msg_description: 'description',
        msg_phone: 'phone',
        msg_active: '_isActiveToggle',
      },
    };
  },
  computed: {
    canGoBack () {
      // return this.$route.name !== 'NotificationTableListAll'
      return true;
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('app.title'),
          style: {
            fontWeight: 'bold',
            width: '250px',
          },
        },
        {
          name: 'created',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.date'),
          style: {
            width: '150px',
            order: this.isMobileView ? 2 : 0,
          },
        },
        {
          name: '',
          onlyMobile: true,
          style: {
            order: 2,
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '100%',
            minWidth: 'auto',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            order: this.isMobileView ? 2 : 0,
            // maxWidth: '20%',
            // width: '500px',
          },
        },
        {
          name: 'remove',
          label: this.$i18n.t('app.remove'),
          permissions: 'delete-messages',
          isMobileHeader: false,
          canShowCellFunc: (row) => Boolean(row.is_user_message),
          style: {
            width: '60px',
          },
          slots: {
            remove: true,
          },
        },
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('view-messages'),
          // selectable: this.checkHasPermission('edit-messages'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        activeStatus: false,
        notification: true,
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchNotificationsList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchData();
    this.fetchNotifCount();

    this.intervalCode = setInterval(() => {
      this.fetchData();
    }, 10000);
  },
  beforeDestroy () {
    clearInterval(this.intervalCode);
    this.intervalCode = null;
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getNotificationList: 'getNotificationList',
      deleteNotification: 'deleteNotification',
      checkNotificationCount: 'checkNotificationCount',
    }),
    async fetchData () {
      await this.fetchNotificationsList();
      this.isLoadingData = false;
    },
    async fetchNotificationsList () {
      const params = {
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        is_user_message: this.notificationSelection,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getNotificationList(params);
        let tempData = res.data.data || [];
        tempData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.notificationsData = this.addAdditionalData(tempData);
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchNotifCount () {
      await this.checkNotificationCount();
    },
    async removeNotification (evt) {
      const params = {
        id: evt.id,
      };
      try {
        const res = await this.deleteNotification(params);
        this.showSuccessMsg(res.data.message);
        this.fetchNotificationsList();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    notificationSelected (evt) {
      this.notificationSelection = evt;
      this.resetPageFilter();
      this.fetchNotificationsList();
    },
    async rowSelect (rowEvt) {
      // if (rowEvt.is_user_message) {
      this.$router.push({
        name: 'NotificationEdit',
        params: {
          msg_id: rowEvt.id,
        },
      });
      // }
    },
    addAdditionalData (tempData) {
      return tempData;
    },
  },
};
</script>

<style lang="scss" scoped>
#notifications-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;

  ::v-deep {
    .table {
      overflow: hidden;
    }

    .body {
      padding: 4px 0 6px 0;
      overflow: auto;
    }
  }
}
</style>
